import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TablePaginationActions from "./TablePaginationActions";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import Edit from "@mui/icons-material/Edit";
import Save from "@mui/icons-material/Save";
import Cancel from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import TableToolbar from "./TableToolbar";
import { styled } from "@mui/material/styles";
import { useAuth } from "../../../contexts/AuthContext";
import { useLoading } from "../../../contexts/LoadingContext";
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { deleteGame, updateGame } from "../../../server/games-api/games";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#e6f0fa",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Checkbox ref={resolvedRef} {...rest} />
      </>
    );
  }
);

// Create an editable action renderer
const EditableAction = ({ row, setEditableRowIndex, editableRowIndex,data,setLoading }) => {

  if (editableRowIndex !== row.index) {
    return (
        <IconButton
          aria-label="edit"
          onClick={async () => {
            setEditableRowIndex(row.index);
          }}
        >
          <Edit />
        </IconButton>
    );
  } else {
    return (
      <>
          <IconButton
            aria-label="save"
            onClick={async () => {
              setLoading(true);
              await updateGame(data[editableRowIndex].id, row.values);
              setEditableRowIndex(null);
              setLoading(false);
            }}
          >
            <Save />
          </IconButton>
          <IconButton
            aria-label="cancel"
            onClick={async () => {
              //setLoading(true);
              setEditableRowIndex(null);
              //setLoading(false);
            }}
          >
            <Cancel />
          </IconButton>
      </>
    );
  }
};

// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id, readonly},
  updateMyData, // This is a custom function that we supplied to our table instance
  editableRowIndex, // index of the row we requested for editing
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);
  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed externall, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return index === editableRowIndex ? (
    <TextField
      margin="normal"
      fullWidth
      name={id}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={readonly ? "disabled" : ""}
    />
  ) : (
    <p>{value}</p>
  );
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
};

const EnhancedTable = ({
  columns,
  data,
  setData,
  updateMyData,
  skipPageReset,
  canEdit,
  addNewRecordTitle,
}) => {
  const [editableRowIndex, setEditableRowIndex] = useState(null);
  const { currentUser } = useAuth();
  const showEditableComponent =
    currentUser && currentUser.role === "admin" && canEdit ? true : false;
  const { setLoading } = useLoading();
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetPage: !skipPageReset,
      updateMyData,
      editableRowIndex,
      setEditableRowIndex,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.allColumns.push((columns) =>
        showEditableComponent
          ? [
              {
                id: "selection",
                Header: ({ getToggleAllRowsSelectedProps }) => (
                  <>
                    <IndeterminateCheckbox
                      {...getToggleAllRowsSelectedProps()}
                    />
                  </>
                ),
                Cell: ({ row }) => (
                  <div>
                    <IndeterminateCheckbox
                      {...row.getToggleRowSelectedProps()}
                    />
                  </div>
                ),
              },
            ...columns,
              {
                accessor: "edit",
                id: "edit",
                Header: "Edit",
                Cell: ({ row, setEditableRowIndex, editableRowIndex ,data}) => (
                  <EditableAction
                    row={row}
                    setEditableRowIndex = {setEditableRowIndex}
                    editableRowIndex = {editableRowIndex}
                    data = {data}
                    setLoading = {setLoading}
                  />
                ),
              },
            ]
          : [...columns]
      );
    }
  );

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value));
  };

  const removeByIndexs = (array, indexs) =>
    array.filter((_, i) => !indexs.includes(i));

  const deleteRecordHandler = async (event) => {
    const dataIndex = Object.keys(selectedRowIds).map((x) => parseInt(x, 10));
    const newData = removeByIndexs(data, dataIndex);
    dataIndex.forEach(async (element) => {
      await deleteGame(data[element].id);
    });
    setData(newData);
    console.log("selectedRowIds", selectedRowIds);
    // await deleteGame(selectedRowIds[0]);
  };

  const addNewRecordHandler = (newRecord) => {
    const updatedRecord = data.concat([newRecord]);
    setData(updatedRecord);
  };

  // Render the UI for your table
  return (
    <TableContainer component={Paper}>
      <TableToolbar
        numSelected={Object.keys(selectedRowIds).length}
        deleteRecordHandler={deleteRecordHandler}
        addNewRecordHandler={addNewRecordHandler}
        preGlobalFilteredRows={preGlobalFilteredRows}
        setGlobalFilter={setGlobalFilter}
        globalFilter={globalFilter}
        addNewRecordTitle={addNewRecordTitle}
        showEditableComponent={showEditableComponent}
      />
      <MaUTable {...getTableProps()} size="small">
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()} head>
              {headerGroup.headers.map((column) => (
                <StyledTableCell
                  {...(column.id === "selection"
                    ? column.getHeaderProps()
                    : column.getHeaderProps(column.getSortByToggleProps()))}
                >
                  {column.render("Header")}
                  {column.id !== "selection" ? (
                    <TableSortLabel
                      active={column.isSorted}
                      // react-table has a unsorted state which is not treated here
                      direction={column.isSortedDesc ? "desc" : "asc"}
                    />
                  ) : null}
                </StyledTableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <StyledTableRow {...row.getRowProps()} hover>
                {row.cells.map((cell) => {
                  return (
                    <StyledTableCell {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </StyledTableCell>
                  );
                })}
              </StyledTableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              sx={{ width: "100%" }}
              rowsPerPageOptions={[
                5,
                10,
                25,
                { label: "All", value: data.length },
              ]}
              colSpan={6}
              count={data.length}
              rowsPerPage={pageSize}
              page={pageIndex}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </MaUTable>
    </TableContainer>
  );
};

export default EnhancedTable;
