import { auth } from "../../firebase";
import httpClient from "../http-client";
import { inMemoryPersistence } from "firebase/auth";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";

export const login = async (email, password) => {
  try {
    auth.setPersistence(inMemoryPersistence);
    return signInWithEmailAndPassword(auth, email, password).then(
      async (userCredential) => {
        //const token = await user.getIdTokenResult();
        await httpClient.post("auth/setSession", {
          token: userCredential.user.accessToken,
        });
        return userCredential;
      }
    );
  } catch (error) {
    localStorage.clear();
    throw new Error(error);
  }
};

// export const loginV2= async (email, password) => {
//   const user = await httpClient.get("auth/signIn", {
//     email: email,
//     password: password
//   })

// }

export const register = (userDetails) => {
  return createUser(userDetails);
};

async function createUser(userDetails) {
  try {
    const response = await httpClient.post("auth/signUp", {
      email: userDetails.email,
      password: userDetails.password,
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      role: userDetails.role,
    });
    if (response.status === "201") return response.status;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    throw new Error(error);
  }
}

export const logout = async () => {
  await auth.signOut();
  localStorage.clear();
  return;
};

export const resetPassword = (email) => {
  return sendPasswordResetEmail(auth, email);
};

export const refreshToken = async () => {
  const response = await httpClient.get("auth/refreshToken");
  return response.data;
};
