import { BrowserRouter as Router } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Box from "@mui/material/Box";
import { AuthProvider } from "./contexts/AuthContext";
import {LoadingProvider} from "./contexts/LoadingContext"
import DrawerAppBar from "./components/Navigation/DrawerAppBar";
import MainContainer from "./components/Common/MainContainer";

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <AuthProvider>
        <LoadingProvider>
          <Router>
            <DrawerAppBar />
            <Box id="main_app_box" mt={7}>
              <MainContainer/>
            </Box>
          </Router>
        </LoadingProvider>
      </AuthProvider>
    </LocalizationProvider>
  );
}

export default App;
