import Container from "@mui/material/Container";
import RouteTable from "../Navigation/RouteTable";
import Spinner from "./Spinner";
import { useLoading } from "../../contexts/LoadingContext";

export default function MainContainer() {
  const { loading } = useLoading();
  return (
    <>
      {loading && <Spinner />}
      <Container sx={{ mt: 5 }}>
        <RouteTable />
      </Container>
    </>
  );
}
