import React, { useState, useEffect } from "react";
import EnhancedTable from "../../Controls/Table/EnhancedTable";
import { getGames } from "../../../server/games-api";
import { useLoading } from "../../../contexts/LoadingContext";

function Results() {
  const columns = React.useMemo(
    () => [
      {
        Header: "Game Name",
        accessor: "gameName",
        readonly: true,
      },
      {
        Header: "Date",
        accessor: "gameDate",
        readonly: true,
      },
      {
        Header: "Time",
        accessor: "gameTime",
        readonly: true,
      },
      {
        Header: "Result",
        accessor: "result",
        readonly: false,
      },
    ],
    []
  );

  const readonlyColumns = columns.filter((c) => c.readonly);

  const [games, setGames] = useState([]);
  const { setLoading } = useLoading();

  useEffect(() => {
    const getdata = async () => {
      setLoading(true);
      setGames(await getGames());
      setLoading(false);
    };
    getdata();
  }, []);

  const [setSkipPageReset] = React.useState(false);

  const updateMyData = (rowIndex, columnId, value, disabled) => {
    setSkipPageReset(true);
    setGames((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  return (
    <EnhancedTable
      columns={columns}
      readonlyColumns={readonlyColumns}
      data={games}
      canEdit={true}
      setData={setGames}
      updateMyData={updateMyData}
      addNewRecordTitle=""
    />
  );
}

export default Results;
