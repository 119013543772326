import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const PrivateRoute = () => {
  const location = useLocation();
  const { currentUser } = useAuth();
  const currentLocation = location.pathname;
  if (currentUser) {
    if (
      currentLocation === "/admin/register" &&
      (currentUser.role === undefined ||
      currentUser.role === "helper")
    ) {
      return <Navigate to={"/unauthorized"} />;
    }
     return <Outlet/> 
  }
  return <Navigate to={"/login"} />;
};

export default PrivateRoute;
