import axios from "axios";

// Do not Remove
// const dateTransformer = (data) => {
//   if (data instanceof Date) {
//     // do your specific formatting here
//     return data.toLocaleString();
//   }
//   if (Array.isArray(data)) {
//     return data.map((val) => dateTransformer(val));
//   }
//   if (typeof data === "object" && data !== null) {
//     return Object.fromEntries(
//       Object.entries(data).map(([key, val]) => [key, dateTransformer(val)])
//     );
//   }
//   return data;
// };

const httpClient = axios.create();
// const httpClient = axios.create({
//   transformRequest: [dateTransformer].concat(axios.defaults.transformRequest),
// });
httpClient.defaults.baseURL = "https://node-api-5e10.onrender.com/api";
//httpClient.defaults.baseURL = "https://localhost:5000/api/";
httpClient.defaults.withCredentials = true;

httpClient.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    throw new Error(error);
  }
);

httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

export default httpClient;
