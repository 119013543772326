import { db } from "../../firebase";
import {
  collection,
  addDoc,
  deleteDoc,
  doc,
  query,
  orderBy,
  getDocs,
  updateDoc
} from "firebase/firestore";
import { format } from "date-fns";

const TABLE_NAME = "games";
const DEFAULT_RESULT = "awaiting..";
const SORT_BY_COLUMN = "gameDateTime";
const SORT_BY_ORDER = "desc";
const DATE_FORMAT = "dd/MM/yyyy";
const TIME_FORMAT = "hh:mm a";

const gamesCollectionRef = collection(db, TABLE_NAME);

export const addGame = async (gameData) => {
   return await addDoc(gamesCollectionRef, { ...gameData, result: DEFAULT_RESULT });
};

export const deleteGame = async (id) => {
  const gameDoc = doc(db, TABLE_NAME, id);
  await deleteDoc(gameDoc);
};

export const getGames = async () => {
  const gameSnapShot = await getDocs(query(gamesCollectionRef, orderBy(SORT_BY_COLUMN, SORT_BY_ORDER )));
  const data =[];
  gameSnapShot.forEach((doc) =>
    data.push({
      gameName: doc.data().gameName,
      gameDate: format(doc.data().gameDateTime.toDate(),DATE_FORMAT),
      gameTime: format(doc.data().gameDateTime.toDate(),TIME_FORMAT),
      result: doc.data().result,
      id: doc.id,
    })
  );
  return data;
};

export const updateGame = async(id,data)=> {
  const gameDoc = doc(db, TABLE_NAME, id);
  await updateDoc(gameDoc,{
    result: data.result,
  });
}