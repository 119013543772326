import React from "react";
import { Route, Routes } from "react-router-dom";
import GameHome from "../Admin/Games/GameHome";
import PrivateRoute from "../../HOC/PrivateRoute";
import Home from "../Common/Home";
import AddUser from "../Login/AddUser";
import SignIn from "../Login/Login"
import ResetPassword from "../Login/ResetPassword";
import Unauthorized from "../Common/Unauthorized";
import NotFound from "../Common/NotFound";
//import axios from "axios";

function RouteTable() {
 
  return (
    <>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route exact path="/" element={<Home />} />
        <Route path="/admin" element={<PrivateRoute />}>
          <Route path="/admin/register" element={<AddUser />} />
          <Route path="/admin/games" element={<GameHome />} />
        </Route>
        <Route path="/login" element={<SignIn />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
      </Routes>
    </>
  );
}

export default RouteTable;
