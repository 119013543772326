import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { logout } from "../../server/auth-api";
import { useEffect, useRef } from "react";

const drawerWidth = 240;

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const { currentUser, setCurrentUser } = useAuth();
  const navigate = useNavigate();

  const authButtonTextRef = useRef();
  authButtonTextRef.current = currentUser !== null ? "Logout" : "Login";
  useEffect(() => {
    console.log(currentUser);
  }, [currentUser]);
  const handleAuth = async () => {
    try {
      if (currentUser) {
        await logout();
        setCurrentUser(null);
        return navigate("/");
      } else {
        return navigate("/login");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Chor Sipahi
      </Typography>
      <Divider />
      <List>
        <ListItem key="home" disablePadding>
          <ListItemButton sx={{ textAlign: "center" }} href={"/"}>
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>
        {currentUser && (
          <>
            <ListItem key="add-user" disablePadding>
              <ListItemButton
                sx={{ textAlign: "center" }}
                href={"admin/register"}
              >
                <ListItemText primary="Add User" />
              </ListItemButton>
            </ListItem>
            <ListItem key="games" disablePadding>
              <ListItemButton sx={{ textAlign: "center" }} href={"admin/games"}>
                <ListItemText primary="Games" />
              </ListItemButton>
            </ListItem>
          </>
        )}
        <ListItem key="auth" disablePadding>
          <ListItemButton sx={{ textAlign: "center" }} onClick={handleAuth}>
            <ListItemText primary={authButtonTextRef.current} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            Chor Sipahi
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Button href={"/"} key="home" sx={{ color: "#fff" }}>
              Home
            </Button>
            {currentUser && (
              <>
                <Button
                  href={"/admin/register"}
                  key="add-user"
                  sx={{ color: "#fff" }}
                >
                  Add User
                </Button>
                <Button
                  href={"/admin/games"}
                  key="games"
                  sx={{ color: "#fff" }}
                >
                  Games
                </Button>
              </>
            )}
            <Button key="login" sx={{ color: "#fff" }} onClick={handleAuth}>
              {authButtonTextRef.current}
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export default DrawerAppBar;
