import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import EnhancedTable from "../Controls/Table/EnhancedTable";
import { useState, useEffect } from "react";
import { getGames } from "../../server/games-api/games";
import { useLoading } from "../../contexts/LoadingContext";

const Home = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Game Name",
        accessor: "gameName",
        disabled: "disabled",
      },
      {
        Header: "Date",
        accessor: "gameDate",
      },
      {
        Header: "Time",
        accessor: "gameTime",
      },
      {
        Header: "Result",
        accessor: "result",
      },
    ],
    []
  );

  const [data, setData] = useState([]);
  const { setLoading } = useLoading();
  useEffect(() => {
    const getdata = async () => {
      setLoading(true);
      setData(await getGames());
      setLoading(false);
    };
    getdata();
  }, []);

  return (
    <>
      <CssBaseline />
      <EnhancedTable
        columns={columns}
        data={data}
        canEdit={false}
      />
    </>
  );
};

export default Home;
