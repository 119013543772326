import React, { useState } from "react";
import { resetPassword } from "../../server/auth-api";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockReset from "@mui/icons-material/LockReset";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { useLocation } from "react-router-dom";

export default function SignIn() {
  const [loading, setLoading] = useLocation(false);
  const [error, setError] = useState("");
  const [successMessage, setMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const resetPasswordData = {
      email: data.get("email"),
    };
    try {
      setError("");
      setMessage("");
      await resetPassword(resetPasswordData.email);
      setMessage("Check your email for instruction");
    } catch (error) {
      console.log(error);
      setError("Not able to perform the action now, plesae try later");
    }
    setLoading(false);
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3}>
        {successMessage && (
          <Alert security="success">
            <AlertTitle>Success</AlertTitle>
            {successMessage}
          </Alert>
        )}
        {error && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        )}
        <Container component="main" maxWidth={"lg"} id="resetpassword_main">
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ mt: 2, bgcolor: "secondary.main" }}>
              <LockReset />
            </Avatar>
            <Typography mt={2} component="h1" variant="h5">
              Reset Password
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ mt: 1, width: "100%" }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={loading}
                sx={{ mt: 3, mb: 2 }}
              >
                Reset
              </Button>
              <Grid container paddingBottom={2}>
                <Grid item xs>
                  <Link href={"/login"} variant="body2">
                    Login
                  </Link>
                </Grid>
                <Grid item>
                  <Link href={"/"} variant="body2">
                    {"Want to explore result? Go to Home"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </Paper>
    </Container>
  );
}
