import Container from "@mui/material/Container";
import Results from "../Results/Results";

function GameHome() {
  return (
    <Container maxWidth="lg" id="result_home_box">
      <Results />
    </Container>
  );
}

export default GameHome;
