import React, { useContext, useState, useEffect } from "react";
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
//import axios from "axios"
const AuthContext = React.createContext();
export const useAuth = () => {
  return useContext(AuthContext);
};
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(() => {
    const userProfile = localStorage.getItem("userProfile");
    if (userProfile) {
      return JSON.parse(userProfile);
    }
    return null;
  });
  const [isUserLoading, setUserLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUserLoading(false);
      user &&
        user.getIdTokenResult().then((token) => {
          const authDetails = {
            userName: user.displayName,
            email: user.email,
            role: token.claims["role"],
          };
          localStorage.setItem("userProfile", JSON.stringify(authDetails));
          setCurrentUser(authDetails);
        });
      return unsubscribe;
    });
  }, []);

  const value = {
    currentUser,
    setCurrentUser,
    isUserLoading,
  };

  return (
    <AuthContext.Provider value={value}>
      {!isUserLoading && children}
    </AuthContext.Provider>
  );
}
