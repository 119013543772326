import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useLoading } from "../../../contexts/LoadingContext";
import { AxiosError } from "axios";
import { addGame } from "../../../server/games-api/games";

const AddNewRecordDialog = (props) => {
  const { setLoading } = useLoading();
  const [setError] = useState("");
  const [open, setOpen] = React.useState(false);
  const [gameDateTime, setDateTime] = useState(new Date());

  const handleClickOpen = () => {
    setOpen(true);
  };

  const closeDialogBox = () => {
    setOpen(false);
  };

  const handleAdd = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const gameData = {
      gameName: data.get("game_name"),
      gameDateTime: gameDateTime,
    };
    console.log(gameData);
    try {
      const response = await addGame(gameData);
      setLoading(false);
      closeDialogBox();
      //return
      return response;
    } catch (error) {
      console.log(error);
      setError("failed to create new game, try again");
      setLoading(false);
      return new AxiosError(error.message);
    }
  };

  return (
    <div>
      <Tooltip title="Add">
        <IconButton aria-label="add" onClick={handleClickOpen}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={closeDialogBox}
        aria-labelledby="form-dialog-title"
      >
        <Box
          component="form"
          onSubmit={handleAdd}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <DialogTitle id="form-dialog-title">Add New Game</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Game Name"
              type="text"
              fullWidth
              id="game_name"
              name="game_name"
            />
            <DateTimePicker
              label="Game Date/Time"
              id="game_date_time"
              name="game_date_time"
              renderInput={(params) => (
                <TextField fullWidth margin="normal" {...params} />
              )}
              value={gameDateTime}
              onChange={(newValue) => {
                setDateTime(newValue);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialogBox} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Add
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
};

export default AddNewRecordDialog;
