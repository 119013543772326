import { createContext, useContext, useState } from "react";

const LoadingContext = createContext({
    loading: false,
    setLoading: null
})

export const LoadingProvider = ({ children }) => {
    const [loading, setLoading] = useState(false)
    const value = { loading, setLoading }
    
    return (
        <LoadingContext.Provider value={value}>
            {children}
        </LoadingContext.Provider>
    )
}

export const useLoading = () => {
    var loadingContext = useContext(LoadingContext)
    if (!loadingContext)
        throw new Error("useLoading must be used within LoadingProvider");
    return loadingContext;
}
