import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useLoading } from "../../contexts/LoadingContext";
import { register } from "../../server/auth-api";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import AdornmentPassword from "../Controls/AdornmentPassword";

const AddUser = (props) => {
  const { loading, setLoading } = useLoading(false);
  const [error, setError] = useState("");
  const [role, setRole] = useState("helper");

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const data = new FormData(event.currentTarget);
    const userDetails = {
      firstName: data.get("firstName"),
      lastName: data.get("lastName"),
      email: data.get("email"),
      password: data.get("password"),
      confirmPassword: data.get("confirm-password"),
      role: data.get("role"),
    };

    if (userDetails.password !== userDetails.confirmPassword)
      return setError("passwords do not match");
    try {
      setError("");
      const response = await register(userDetails);
      response && navigate("admin/games");
    } catch (err) {
      console.log(err);
      setError("failed to create user account");
    }
    setLoading(false);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  return (
    <>
      <Container maxWidth="md">
        <Paper elevation={3}>
          <Container
            id="add_user_container"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {error && (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle> {error}
              </Alert>
            )}
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <PersonAdd />
            </Avatar>
            <Typography component="h1" variant="h5">
              Add User
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <Grid container spacing={1} columns={16}>
                <Grid item xs={8}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoFocus
                  />
                </Grid>
              </Grid>
              <TextField
                margin="normal"
                type="email"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoFocus
              />
              <AdornmentPassword
                type="password"
                required
                fullWidth
                id="password"
                label="Password"
                name="password"
                autoFocus
              />
              <AdornmentPassword
                type="password"
                required
                fullWidth
                id="confirm-password"
                label="Confirm Password"
                name="confirm-password"
                autoFocus
              />
              <TextField
                margin="normal"
                id="role"
                name="role"
                fullWidth
                select
                value={role}
                label="Role"
                onChange={handleRoleChange}
                autoFocus
              >
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="helper">Helper</MenuItem>
              </TextField>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={loading}
                sx={{ mt: 3, mb: 2 }}
              >
                Add User
              </Button>
            </Box>
          </Container>
        </Paper>
      </Container>
    </>
  );
};

export default React.memo(AddUser);
